import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import './Home.css';

const Home = () => {
  const { courses, styles } = useSelector((state) => state.course || {});
  // Fetch styles and courses from Redux store
  // Dispatch fetchStyles and fetchCourses when the component mounts
  
  const backgroundColor =
    styles && styles[0]?.pages?.backgroundColor
      ? styles[0]?.pages?.backgroundColor
      : '#f8f9fa';
  const fontColor =
    styles && styles[0]?.pages?.fontColor ? styles[0]?.pages?.fontColor : '#000000';
  const image =
    courses && courses[0]?.page1
      ? courses[0]?.page1
      : 'https://img.freepik.com/free-photo/certificate-with-ribbon-bow-books_23-2148769681.jpg?t=st=1732643641~exp=1732647241~hmac=480f5cf6c12dc5faefb8c95813f6e9389ae6f46b860f7c8ae6edef6e9cdc36f0&w=1060';

  return (
    <Container
      fluid
      className="d-flex align-items-center justify-content-center p-0"
      id="Home"
      style={{
        backgroundColor: backgroundColor, // Apply dynamic background color
        color: fontColor, // Apply dynamic font color
        width: '100%',
      }}
    >
      <Helmet>
        <title>SG Academy - Distance Education Programs</title>
        <meta
          name="description"
          content="Join SG Academy for a wide range of distance education programs. We offer undergraduate, postgraduate, diploma, and vocational courses to help you advance your education."
        />
        <meta
          name="keywords"
          content="SG Academy, distance education, undergraduate courses, postgraduate courses, diploma programs, B.Ed, M.Ed, Kerala, Palakkad"
        />
      </Helmet>
      <div
        className="image-container"
        style={{
          width: '100%',
          height: '100vh', // Ensures full height of the viewport
          overflow: 'hidden', // Prevents overflow for the cover effect
        }}
      >
        <img
          src={image}
          alt="SG Academy - Education Programs"
          className="img-fluid responsive-image"
        />
      </div>
    </Container>
  );
};

export default Home;
