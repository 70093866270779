import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import DATAS from './DATAS'; // Fallback courses data
import { useDispatch, useSelector } from 'react-redux';
import { fetchStyles, fetchCourses } from '../../reduxtoolkit/courseSlice';

const SubCourses = () => {
  const { level } = useParams(); // Get course level from URL
  const [localStyles, setLocalStyles] = useState({
    backgroundColor: '#f8f9fa',
    fontColor: '#000000',
  });

  const dispatch = useDispatch();

  const { courses, styles } = useSelector((state) => state.course || {});
  // Fetch courses and styles from Redux store

  // Dispatch actions when component mounts
  // useEffect(() => {
  //   dispatch(fetchStyles());
  //   dispatch(fetchCourses());
  // }, [dispatch]);

  // Get relevant courses based on `level`
  const Data = React.useMemo(() => {
    if (courses?.[0]?.[level]?.data) {
      return courses[0][level].data; // Dynamic access based on `level`
    }
    return DATAS?.[level]?.data || []; // Fallback to local DATAS
  }, [courses, level]);

  // Styles fallback
  const backgroundColor = styles?.[0]?.pages?.backgroundColor || localStyles.backgroundColor;
  const fontColor = styles?.[0]?.pages?.fontColor || localStyles.fontColor;

  const handleContactNow = (text1, text2) => {
    const message = `I'm interested in  ${text1}, ${text2}. Please give me more details.`;
    const encodedMessage = encodeURIComponent(message);
    const phoneNumber = '+918606378283'; // Replace with your WhatsApp number
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(whatsappUrl, '_blank');
  };

  if (!Data || Data.length === 0) {
    return <div>No courses available for this category.</div>;
  }

  return (
    <Container className=' mt-2'>
      <center><h2>{level.toUpperCase()}</h2></center>
      <Row className="g-3">
        {Data.map((course, index) => (
          <Col key={index} lg={3} md={4} sm={12}>
            <Card style={{ width: '100%', backgroundColor, color: fontColor }}>
              <Card.Body>
                <Card.Title>{course.name}</Card.Title>
                <Card.Text>{course.description || 'No description available.'}</Card.Text>
                <div className="d-flex gap-2 flex-column">
                  
                  <Button
                    className=""
                    onClick={() => handleContactNow(level, course.name)}
                  >
                  Learn More
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default SubCourses;
