import React, { useState, useEffect } from 'react';
import Navbar from './Components/Navbar/Navbar';
import Contact from './Components/contact/Contact';
import Map from './Components/Map/Map';
import About from './Components/About/About';
import Footer from './Components/Footer/Footer';
import Gallery from './Components/Gallery/Gellery';
import Courses from './Components/Coures/Courses';
import Home from './Components/Home/Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from './Components/ScrollTop';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Reviews from './Components/Gallery/Reviews';
import { Analytics } from '@vercel/analytics/react';
import { Helmet } from 'react-helmet';
import DataEditor from './Components/Edith/DataEditor';
import Login from './Components/Edith/Login';
import EditStyle from './EdithStyle/edithStyle';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCourses, fetchStyles } from './reduxtoolkit/courseSlice'; // Import 
import SubCourses from './Components/Coures/detailes';
import LoadingAnimation from './Components/Loading/loading';
import './App.css';

const App = () => {
  const [isUserAuthenticated, setUserAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
const [showGallery, setShowGallery] = useState(false);
  // Redux state
  const dispatch = useDispatch();
  const { courses, styles, error } = useSelector((state) => state.course);

  setTimeout(() => {
    setLoading(false);
  }, 3000);

  // Fetch data when the component mounts
  useEffect(() => {
    dispatch(fetchCourses());
    dispatch(fetchStyles());

    const storedUser = localStorage.getItem('myaccount');
    if (storedUser) {
      try {
        const user = JSON.parse(storedUser);
        if (user.name === 'SG' && user.pass === 'SG1983') {
          setUserAuthenticated(true);
        } else {
          setUserAuthenticated(false);
        }
      } catch (error) {
        console.error('Error parsing localStorage data:', error);
        setUserAuthenticated(false);
      }
    }
  }, [dispatch]);
  

  // Default layout for the app
  const DefaultLayout = () => (
    <div
      className="flexs-all-col"
      style={{
        backgroundColor: styles?.[0]?.pages?.backgroundColor || 'white',
        color: styles?.[0]?.pages?.fontColor || 'black',
        width: '100%',
        padding: 'none',
        margin: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Home DATAS={courses} />
    
      <Courses DATAS={courses} />
      
      {showGallery && <Gallery DATAS={courses} />} 
<button 
        onClick={() => setShowGallery(!showGallery)}
        style={{
          marginTop: '20px',
          padding: '10px 20px',
          backgroundColor: '#007bff',
          color: '#fff',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
        }}
      >
        {showGallery ? 'Hide Gallery' : 'Show Gallery'}
      </button>
      
      <About DATAS={courses} />
     
      <Reviews DATAS={courses} />
      
      <Contact DATAS={courses} />
     
    </div>
  );



  return (
    <Router>
      <ScrollToTop />
      <div
        className="App flexs flex-column bg-light"
        style={{
          backgroundColor: styles?.[0]?.pages?.backgroundColor || 'white',
          color: styles?.[0]?.pages?.fontColor || 'black',
        }}
      >
        <Helmet>
          <title>SG Academy - Learn now</title>
          <meta name="description" content="Open Degree, tuition, ..." />
        </Helmet>
        <Navbar DATAS={courses} />
        <div
          style={{
            position: 'fixed',
            bottom: '50px',
            right: '40px',
            zIndex: 2,
          }}
        >
          <a
            className="cardhover"
            href="https://wa.me/+918606378283?text=Hello%20Sir..!%20Could%20I%20have%20the%20details%20please...!" 
                        aria-label="Contact via WhatsApp"
          >
            <WhatsAppIcon
              style={{ color: 'whitesmoke' }}
              fontSize="large"
              className="bg-success rounded-circle"
            />
          </a>
        </div>
        <div
          style={{
            width: '100%',
            margin: '0',
            backgroundColor: styles?.[0]?.pages?.backgroundColor || 'white',
            color: styles?.[0]?.pages?.fontColor || 'black',
          }}
        >
          <Routes>
            <Route path="/" element={<DefaultLayout />} />
            <Route path="/Home" element={<DefaultLayout />} />
            <Route
              path="/OurCourses"
              element={
                <>
                  <Courses DATAS={courses} /> <Reviews DATAS={courses} />
                </>
              }
            />
            <Route
              path="/Gallery"
              element={
                <>
                  <Gallery DATAS={courses} /> <Reviews />
                </>
              }
            />
            <Route
              path="/aboutus"
              element={
                <>
                  <About DATAS={courses} /> <Reviews DATAS={courses} />
                </>
              }
            />
            <Route path="/GetAdmission" element={<Contact DATAS={courses} />} />
            <Route path="/contactus" element={<Contact DATAS={courses} />} />
            <Route path="/map" element={<Map DATAS={courses} />} />
            <Route path="/courses/:level" element={<SubCourses />} />

            <Route
              path="/edit"
              element={isUserAuthenticated ? <DataEditor /> : <Login />}
            />
            <Route
              path="/style"
              element={isUserAuthenticated ? <EditStyle /> : <Login />}
            />
            <Route path="*" element={<div>404 - Page Not Found</div>} />
          </Routes>
        </div>
        <Footer />
        <Analytics />
      </div>
    </Router>
  );
};

export default App;
