import React, { useState } from 'react';
import { Modal, Button, ListGroup } from 'react-bootstrap';

const UniversityList = ({ courses }) => {
  const [showModal, setShowModal] = useState(false);

  // Default list of universities
  let Datas = [
    "CALICUT UNIVERSITY",
    "KERALA UNIVERSITY",
    "MAHATHMA GANDHI UNIVERSITY (MG UNIVERSITY)",
    "SREENARAYANAGURU OPEN UNIVERSITY (SGOU)",
    "ANNAMALAI UNIVERSITY",
    "MADRAS UNIVERSITY",
    "MADURAI KAMARAJ UNIVERSITY",
    "JAIN UNIVERSITY",
    "ALIGARH MUSLIM UNIVERSITY",
    "ALAGAPPA UNIVERSITY",
    "BHARATHIDASAN UNIVERSITY",
    "BHARATHIAR UNIVERSITY",
    "PERIYAR UNIVERSITY",
    "INDIRA GANDHI OPEN UNIVERSITY (IGNOU)"
  ];

  // Override with data from Redux if available
  if (courses && courses[0]) {
    Datas = courses[0]?.universities?.data || Datas;
  }

  return (
    <>
      <Button onClick={() => setShowModal(true)} >Check Available Universities</Button>

      {/* Modal to display universities */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="md"
        centered
        aria-labelledby="university-list-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="university-list-modal">Available Universities</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Datas.length > 0 ? (
            <ListGroup>
              {Datas.map((university, index) => (
                <ListGroup.Item key={index}>{university}</ListGroup.Item>
              ))}
            </ListGroup>
          ) : (
            <p>No universities available at the moment.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UniversityList;
