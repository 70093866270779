import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const FETCH_INTERVAL = 36000; // milliseconds


const initialState = {
  courses: JSON.parse(localStorage.getItem('courses')) || [],
  styles: JSON.parse(localStorage.getItem('styles')) || null,
  loading: false,
  error: null,
};

const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    setCourses: (state, action) => {
      state.courses = action.payload;
      localStorage.setItem('courses', JSON.stringify(action.payload));
    },
    setStyles: (state, action) => {
      state.styles = action.payload;
      localStorage.setItem('styles', JSON.stringify(action.payload));
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
});

export const { setCourses, setStyles, setLoading, setError, clearError } = courseSlice.actions;

// Axios instance with timeout
const axiosInstance = axios.create({
  timeout: 10000, // 10 seconds timeout
});

// Helper to check if data should be fetched
const shouldFetchData = (key) => {
  const lastFetchTime = localStorage.getItem(`${key}_lastFetch`);
  if (!lastFetchTime) return true;

  const currentTime = Date.now();
  return currentTime - parseInt(lastFetchTime, 10) > FETCH_INTERVAL;
};

// Fetch courses at open/reload time only
export const fetchCourses = () => async (dispatch) => {
  if (!shouldFetchData('courses')) return;

  dispatch(setLoading(true));
  dispatch(clearError());

  try {
    const response = await axiosInstance.get('https://sgacademyserver.onrender.com/api/data');
    dispatch(setCourses(response.data));
    localStorage.setItem('courses_lastFetch', Date.now().toString());
  } catch (error) {
    const localData = JSON.parse(localStorage.getItem('courses'));
    if (localData) {
      dispatch(setCourses(localData));
    }
    if (error.code === 'ECONNABORTED') {
      dispatch(setError('Request timed out. Please try again.'));
    } else {
      dispatch(setError(error.message || 'Error fetching courses'));
    }
  } finally {
    dispatch(setLoading(false));
  }
};

// Fetch styles at open/reload time only
export const fetchStyles = () => async (dispatch) => {
  if (!shouldFetchData('styles')) return;

  dispatch(setLoading(true));
  dispatch(clearError());

  try {
    const response = await axiosInstance.get('https://sgacademyserver.onrender.com/api/styles/');
    dispatch(setStyles(response.data));
    localStorage.setItem('styles_lastFetch', Date.now().toString());
  } catch (error) {
    const localData = JSON.parse(localStorage.getItem('styles'));
    if (localData) {
      dispatch(setStyles(localData));
    }
    if (error.code === 'ECONNABORTED') {
      dispatch(setError('Request timed out. Please try again.'));
    } else {
      dispatch(setError(error.message || 'Error fetching styles'));
    }
  } finally {
    dispatch(setLoading(false));
  }
};

export default courseSlice.reducer;
