import React, { useEffect, useState } from 'react';
import { Container, Card, Button } from 'react-bootstrap';
import { Typography, Box, Grid } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStyles, fetchCourses } from '../../reduxtoolkit/courseSlice';
import UniversityList from './UniversityList';
import './Coures.css';

const Courses = () => {
  const [localStyles] = useState({
    backgroundColor: '#f8f9fa',
    fontColor: '#000000',
  });

  const demodata = [
    {
      level: 'ug Programmes',
      description: "Explore various undergraduate courses offered by 𝐒𝐆 𝐀𝐂𝐀𝐃𝐄𝐌𝐘.",
      image: "https://img.freepik.com/free-photo/academy-certification-curriculum-school-icon_53876-122521.jpg",
      availables: "Morning, Evening, Weekend (online/offline/distance/regular)",
      duration: "3-year",
    },
    {
      level: 'pg Programmes',
      description: "Pursue advanced postgraduate courses to elevate your academic career.",
      image: "https://img.freepik.com/free-photo/young-student-wearing-graduation-cape-looking-her-notebook_114579-92485.jpg",
      availables: "Morning, Evening, Weekend (online/offline/distance/regular)",
      duration: "2-year",
    },
    {
      level: 'more services',
      description: "Diploma and other specialized programs to enhance your skills.",
      image: "https://img.freepik.com/free-photo/woman-with-graduation-diploma_23-2148928762.jpg",
      availables: "Morning, Evening, Weekend (online/ offline/ distance/ regular)",
    },
  ];

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { courses, styles } = useSelector((state) => state.course || {});
// useEffect(() => {
  //   dispatch(fetchStyles());
  //   dispatch(fetchCourses());
  // }, [dispatch]);
  

  const Data = React.useMemo(() => {
    if (courses && courses[0]) {
      const Datas = courses[0];
      return [
        {
          level: 'ug Programmes',
          description: Datas?.ugcourses?.description || demodata[0].description,
          image: demodata[0].image,
          availables: Datas?.ugcourses?.availables || demodata[0].availables,
          duration: Datas?.ugcourses?.duration || demodata[0].duration,
        },
        {
          level: 'pg Programmes',
          description: Datas?.pgcourses?.description || demodata[1].description,
          image: demodata[1].image,
          availables: Datas?.pgcourses?.availables || demodata[1].availables,
          duration: Datas?.pgcourses?.duration || demodata[1].duration,
        },
        {
          level: 'more services',
          description: Datas?.moreservices?.description || demodata[2].description,
          image: demodata[2].image,
          availables: Datas?.moreservices?.availables || demodata[2].availables,
          duration: Datas?.moreservices?.duration,
        },
      ];
    }
    return demodata;
  }, [courses, demodata]);

  const backgroundColor = styles?.[0]?.pages?.backgroundColor || localStyles.backgroundColor;
  const fontColor = styles?.[0]?.pages?.fontColor || localStyles.fontColor;

  const handleLearnMore = (index) => {
    // const Level = courseLevel.replace(/\s+/g, ''); // Remove all spaces
    let Level = ""
    if(index===0){
      Level = "ugcourses"
    }else if(index===1){
      Level = "pgcourses"
    }else{
      Level = "moreservices"
    }
      
    
    navigate(`/courses/${Level}`);
  };

  return (
    <Container fluid id="Courses" className="p-4" st>
      <Helmet>
        <title>SG Academy Courses</title>
        <meta
          name="description"
          content="Explore the various undergraduate, diploma, postgraduate courses, and services at SG Academy."
        />
        <meta
          name="keywords"
          content="SG Academy, undergraduate courses, postgraduate courses, diploma courses, services, online classes, counseling, study materials"
        />
      </Helmet>
      <Box mb={4} sx={{ backgroundColor: backgroundColor }}>
        <Typography
          variant="h3"
          align="center"
          gutterBottom
          sx={{
            fontWeight: 'bold',
            color: fontColor || '#C8A272',
            fontFamily: 'Roboto Slab, serif',
          }}
        >
          Our Courses & Services
        </Typography>
        <Typography variant="body1" align="center" style={{ fontFamily: 'Roboto Slab, serif', color: fontColor }} paragraph>
        𝐒𝐆 𝐀𝐂𝐀𝐃𝐄𝐌𝐘 offers a wide range of courses and services to help you achieve your academic and career goals. Explore our offerings below.
        </Typography>
        {/* <div className='my-2 p-2'><UniversityList courses={courses}/></div> */}
     

        <div className="cardhover" style={{ backgroundColor: backgroundColor, color: fontColor,paddingTop:'20px',paddingBottom:'20px' }}>
          <Grid container spacing={4}>
            {Array.isArray(Data) && Data.map((courseCategory, index) => (
              <Grid item xs={12} sm={6} md={6} lg={4} key={courseCategory.level}>
                <Card
                  className="cardhover"
                  style={{
                    width: '100%',
                    maxWidth: '300px',
                    margin: '0 auto',
                    backgroundColor,
                    color: fontColor,
                  }}
                >
                  <Card.Body>
                    <div className="">
                      <Card.Img
                        variant="top"
                        src={courseCategory.image}
                        alt={`${courseCategory.level} image`}
                        style={{ height: '200px', objectFit: 'cover' }}
                      />
                      <Card.Title
                        className="text-center mx-2"
                        style={{
                          fontFamily: 'Roboto Slab, serif',
                          color: 'yellow',
                          textAlign: 'center',
                          backgroundColor: 'red',
                        }}
                      >
                        {courseCategory?.level
                          ? courseCategory.level.toUpperCase()
                          : 'Unknown Level'}
                      </Card.Title>
                    </div>
                    <Card.Text style={{ fontFamily: 'Roboto Slab, serif', textAlign: 'center' }}>
                      {courseCategory.duration && `Duration: ${courseCategory.duration}`}
                      <p>{courseCategory.description}</p>
                      <p>Available classes: {courseCategory.availables}</p>
                    </Card.Text>
                    <Card.Text>
                    </Card.Text>

         { index===1&&<UniversityList courses={courses}/>}

                    <Button
                      style={{
                        backgroundColor: fontColor || '#C8A272',
                        borderColor: '#C8A272',
                        color: backgroundColor || '#222222',
                        fontFamily: 'Roboto Slab, serif',
                        textAlign: 'center',
                        marginTop: '5px',
                      }}
                      onClick={() => handleLearnMore(index)}
                    >
                      Learn More
                    </Button>
                  </Card.Body>
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
      </Box>
    </Container>
  );
};

export default Courses;
